<template>
	<div class="overlay" @click="togglePopUp"></div>
	<div class="popUp">
		<CrossIcon class="popUp__crossIcon" @click="togglePopUp"></CrossIcon>
		<h3 class="popUp__header">
			{{ parentObject.header }}
		</h3>
		<div class="popUp__content">
			<div class="popUp__currentPhoto">
				<img :src="currentImagePathMain" alt="Photo of stairs" />
			</div>
			<ul class="popUp__ul">
				<li v-for="(path, index) in imagePaths" :key="index" class="ul__icon" @click="updateMainImage(index)">
					<img :src="path" alt="Photo of stairs" />
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import CrossIcon from '@/assets/sections/sectionGallery/CrossSvg.vue'

export default {
	name: 'GalleryPopUp',
	components: {
		CrossIcon,
	},
	data() {
		return {
			gallerylength: 0,
			currentIndex: 0,
			currentImagePathMain: '',
			imagePaths: [],
		}
	},
	mounted() {
		document.body.style.overflow = 'hidden'
		this.initializeGallery()
	},
	beforeUnmount() {
		document.body.style.overflow = 'auto'
	},
	props: {
		parentObject: Object,
	},
	computed: {
		isMobile() {
			return this.$store.getters.isMobile
		},
	},
	methods: {
		...mapActions(['getImageFromGalleryPath', 'getGalleryLength']),

		async initializeGallery() {
			await this.getGalleryLengthFromStore()
			await this.loadImagePaths()
			this.updateMainImagePath()
		},

		async getGalleryLengthFromStore() {
			const folder = this.parentObject.images.folder
			this.gallerylength = await this.getGalleryLength({ name: folder })
		},

		async updateMainImage(index) {
			const folder = this.parentObject.images.folder
			const quality = this.isMobile ? 'phone' : 'other'
			this.currentImagePathMain = await this.getImageFromGalleryPath({
				name: folder,
				quality: quality,
				index: index,
			})
		},

		async loadImagePaths() {
			const folder = this.parentObject.images.folder
			const paths = []
			for (let i = 0; i < this.gallerylength; i++) {
				const path = await this.getImageFromGalleryPath({
					name: folder,
					quality: 'min',
					index: i,
				})
				paths.push(path)
			}
			this.imagePaths = paths
		},

		async updateMainImagePath() {
			const folder = this.parentObject.images.folder
			const quality = this.isMobile ? 'phone' : 'other'
			this.currentImagePathMain = await this.getImageFromGalleryPath({
				name: folder,
				quality: quality,
				index: 0,
			})
		},

		togglePopUp() {
			this.$store.commit('togglePopUpGallery')
		},
	},
}
</script>

<style lang="scss" scoped>
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 7;
}
.popUp {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 360px;
	height: 100%;
	background-color: $primary-color-dark;
	border-radius: 50px;
	overflow-y: auto;
	z-index: 7;

	.popUp__crossIcon {
		position: absolute;
		top: 30px;
		right: 10%;
		cursor: pointer;
	}

	.popUp__header {
		@include h3;
		margin: 30px 0 0 30px;
	}

	.popUp__currentPhoto {
		height: 350px;
		width: 320px;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
			border-radius: 10px;
		}
	}

	.popUp__ul {
		@include flex(row, flex-start, flex-start);
		width: 320px;
		max-height: 250px;
		flex-wrap: wrap;
		overflow-y: auto;
		align-content: flex-start;

		.ul__icon {
			flex: 0 0 calc(33.33% - 10px);
			height: 95px;
			width: 95px;
			margin: 10px 5px;

			img {
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
				border-radius: 10px;
			}
		}
	}
	.popUp__ul::-webkit-scrollbar {
		width: 4px;
	}

	.popUp__ul::-webkit-scrollbar-track {
		background: #333;
	}

	.popUp__ul::-webkit-scrollbar-thumb {
		background: #555;
		border-radius: 10px;
	}

	.popUp__ul::-webkit-scrollbar-thumb:hover {
		background: #888;
	}

	.popUp__ul::-webkit-scrollbar-button {
		background: #333;
		height: 0px;
	}
}

@media (max-width: 1023px) {
	.popUp__currentPhoto,
	.popUp__ul {
		@include center-margin;
		margin-top: 20px;
	}
}
@media (min-width: 1024px) {
	.popUp {
		width: 950px;
		height: 590px;
		.popUp__crossIcon {
			right: 5%;
		}
		.popUp__content {
			@include flex(row, none, space-evenly);
			margin-top: 10px;
			.popUp__currentPhoto {
				height: 500px;
				width: 400px;
			}

			.popUp__ul {
				max-height: 500px;
				width: 470px;

				.ul__icon {
					height: 140px;
					width: 140px;
					margin: 5px 5px;
				}
			}
		}
	}
}
@media (min-width: 1900px) {
	.popUp {
		width: 1400px;
		height: 800px;

		.popUp__content {
			margin-top: 40px;
			.popUp__currentPhoto {
				height: 656px;
				width: 656px;
			}

			.popUp__ul {
				max-height: 650px;
				width: 550px;

				.ul__icon {
					height: 160px;
					width: 160px;
				}
			}
		}
	}
}
</style>
