<template>
	<nav>
		<div v-if="!isPopUpOpen" class="nav">
			<ul class="nav__ul">
				<li
					v-for="section in navbarLinksArray"
					:key="section.sectionName"
					class="ul__li"
					@click="navigateToSection(section.sectionName)"
					:class="{ activeNavBar: isSectionActive(section.sectionName) }"
				>
					{{ section.link }}
				</li>
			</ul>
		</div>
	</nav>
</template>
<script>
export default {
	name: 'NavBar',
	components: {},
	mounted() {
		window.addEventListener('scroll', this.handleScroll)
	},
	beforeUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	},
	computed: {
		isMobile() {
			return this.$store.getters.isMobile
		},
		navbarLinksArray() {
			return this.$store.state.navbarLinks.map(section => ({
				sectionName: section.name,
				link: section.pl,
			}))
		},
		getCurrentSection() {
			return this.$store.getters.getCurrentSection
		},

		isPopUpOpen() {
			return this.$store.getters.isPopUpOpenGallery
		},
	},
	methods: {
		navigateToSection(sectionName) {
			this.$store.commit('navigateToSection', sectionName)
		},
		isSectionActive(sectionName) {
			const isActive = sectionName === this.getCurrentSection
			return isActive
		},
	},
}
</script>

<style lang="scss" scoped>
.nav {
	position: fixed;
	height: 65px;
	width: 100%;
	background-color: $primary-color-dark;
	box-shadow: 0px 4px 46px 0px rgba(0, 0, 0, 0.47);
	z-index: 5;

	&__ul {
		@include center-absolute;
		@include flex(row, center, space-between);
		width: 320px;
	}

	.ul__li {
		color: $white;
		font-size: 12px;
		font-weight: 300;
		letter-spacing: 0.1em;
	}
	.activeNavBar {
		padding-bottom: 2px;
		border-bottom: 1px solid white;
	}
}

@media (min-width: 1024px) {
	.nav {
		height: 95px;

		&__ul {
			width: 510px;
		}
		.ul__li {
			font-size: 15px;
		}
	}
}

@media (min-width: 1500px) {
	.nav {
		&__ul {
			width: 580px;
		}
		.ul__li {
			font-size: 17px;
		}
	}
}
</style>
