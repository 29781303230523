<template>
	<section>
		<div class="gallery">
			<div class="gallery__header">
				<h3 class="header__h3" @click="gallerySelected()">
					{{ parentObject.header }}
				</h3>
				<ArrowRight :sectionName="'gallery'" class="header__icon"></ArrowRight>
			</div>
			<div class="gallery__content">
				<div class="gallery__icon">
					<BrickIcon v-if="parentObject.icon === 'BrickSvg'" class="iconSvg"></BrickIcon>
					<FloorPlanIcon v-if="parentObject.icon === 'FloorPlanSvg'" class="iconSvg"></FloorPlanIcon>
				</div>
				<div class="carousel">
					<div class="gallery__img">
						<div class="carousel-inner" @click="gallerySelected()">
							<carousel-item
								v-for="(imagePath, index) in imagePaths"
								:key="`item-${index}`"
								:slidePath="imagePath"
								:index="index"
								:currentSlide="currentIndex"
							></carousel-item>
						</div>
					</div>
					<div class="gallery__dots">
						<span
							v-for="index in parentObject.images.imagesLength"
							:key="index"
							:class="{ dots__dot: true, 'dot--active': currentIndex === index - 1 }"
							@click="changeImage(index - 1)"
						></span>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions } from 'vuex'
import CarouselItem from './CarouselItem.vue'
import ArrowRight from '@/assets/additions/ArrowRightSvg.vue'
import BrickIcon from '@/assets/sections/sectionGallery/BrickSvg.vue'
import FloorPlanIcon from '@/assets/sections/sectionGallery/FloorPlanSvg.vue'

export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Gallery',
	components: {
		CarouselItem,
		ArrowRight,
		BrickIcon,
		FloorPlanIcon,
	},
	data() {
		return {
			currentIndex: 0,
			transitionName: 'slide-in',
			imagePaths: [],
		}
	},
	mounted() {
		this.loadImages()
		this.carouselPhoto()
	},
	props: {
		parentObject: Object,
	},
	computed: {
		transitionEffect() {
			return this.transitionName
		},
	},
	methods: {
		...mapActions(['getImageFromGalleryPath']),
		async loadImages() {
			const folder = this.parentObject.images.folder
			const promises = []
			for (let i = 0; i < this.parentObject.images.imagesLength; i++) {
				promises.push(
					this.getImageFromGalleryPath({
						name: folder,
						quality: 'other',
						index: i,
					})
				)
			}
			this.imagePaths = await Promise.all(promises)
		},
		changeImage(index) {
			this.transitionName = index > this.currentIndex ? 'slide-in' : 'slide-out'
			this.currentIndex = index
		},
		carouselPhoto() {
			setInterval(() => {
				this.changeImage((this.currentIndex + 1) % this.parentObject.images.imagesLength)
			}, 3000)
		},
		gallerySelected() {
			this.$emit('gallerySelectedToPopUp', this.parentObject)
		},
	},
}
</script>

<style lang="scss" scoped>
section {
	position: relative;
}
.gallery {
	width: 320px;
	.gallery__header {
		@include flex(row, center, none);
		margin-bottom: 30px;
		color: $primary-color-dark;
		cursor: pointer;

		.header__h3 {
			@include h3;
			border-bottom: 2px solid $primary-color-dark;
		}
		.header__icon {
			margin-left: 15px;
		}
	}

	.gallery__content {
		@include flex(row, center, space-between);
		.gallery__icon {
			align-self: flex-start;
			height: 62px;
			width: 62px;
			position: relative;
			background-color: $primary-color-dark;

			&::after {
				@include positionBar(65px, 0px, 100%, 2px);
			}

			.iconSvg {
				@include center-absolute;
			}
		}
		.gallery__img {
			position: relative;
			overflow: hidden;
			width: 230px;
			height: 230px;

			.carousel-inner {
				cursor: pointer;
				position: relative;
				width: 100%;
				height: 100%;
			}
		}
		.gallery__dots {
			@include flex(none, none, center);
			margin-top: 5px;
			gap: 10px;
			.dots__dot {
				width: 7px;
				height: 7px;
				border: 1px solid $primary-color-dark;
				border-radius: 50%;
				cursor: pointer;
			}

			.dot--active {
				background-color: $primary-color-dark;
			}
		}
	}
}

.slide-in-enter-active,
.slide-in-leave-active,
.slide-out-enter-active,
.slide-out-leave-active {
	transition: all 1s ease-in-out;
}
.slide-in-enter-from {
	transform: translateX(100%);
}
.slide-in-leave-to {
	transform: translateX(-100%);
}
.slide-out-enter-from {
	transform: translateX(-100%);
}
.slide-out-leave-to {
	transform: translateX(100%);
}

@media (min-width: 1024px) {
	.gallery {
		width: 400px;
		margin-left: 50px;

		.gallery__content {
			.gallery__icon {
				height: 80px;
				width: 80px;

				&::after {
					@include positionBar(85px, 0px, 100%, 3px);
				}
			}

			.gallery__img {
				width: 290px;
				height: 290px;

				.gallery__dots {
					margin-top: 10px;
					gap: 15px;

					.dots__dot {
						width: 10px;
						height: 10px;
					}
				}
			}
		}
	}
}

@media (min-width: 1500px) {
	.gallery {
		width: 530px;
		margin-left: 70px;

		.gallery__content {
			.gallery__icon {
				height: 110px;
				width: 110px;

				&::after {
					@include positionBar(115px, 0px, 100%, 5px);
				}
			}

			.gallery__img {
				width: 400px;
				height: 400px;

				.gallery__dots {
					margin-top: 10px;
					gap: 15px;

					.dots__dot {
						width: 10px;
						height: 10px;
					}
				}
			}
		}
	}
}
</style>
