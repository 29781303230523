import { createStore } from 'vuex'

const store = createStore({
	state() {
		return {
			navbarLinks: [
				{ name: 'SectionHome', pl: 'HOME', idHeader: 'HeaderSectionHome' },
				{ name: 'SectionAbout', pl: 'CO TWORZĘ', idHeader: 'HeaderSectionAbout' },
				{ name: 'SectionGallery', pl: 'GALERIA', idHeader: 'HeaderSectionGallery' },
				{ name: 'SectionContact', pl: 'KONTAKT', idHeader: 'HeaderSectionContact' },
			],
			currentSection: 'SectionHome',
			isNavigating: false,
			popUpGalleryisOpen: false,
			sizeIconGallery: Number,
			sizeIconContact: Number,
		}
	},
	mutations: {
		setDevice(state, device) {
			state.device = device
			this.commit('setSizeIcon', {
				sizeType: 'sizeIconGallery',
				mobileSize: 42,
				tabletSize: 55,
				desktopSize: 74,
			})
			this.commit('setSizeIcon', {
				sizeType: 'sizeIconContact',
				mobileSize: 21,
				tabletSize: 18,
				desktopSize: 18,
			})
		},

		navigateToSection(state, sectionName) {
			if (state.isNavigating) {
				return
			}

			state.isNavigating = true
			const section = state.navbarLinks.find(item => item.name === sectionName)
			const sectionElementId = section && section.idHeader

			if (!sectionElementId) {
				return
			}

			const heightMap = sectionElementId === 'HeaderSectionHome' ? 0 : { isMobile: 80, isTablet: 140, isDesktop: 150 }

			const sectionElement = document.getElementById(sectionElementId)

			const height = heightMap[this.getters.isMobile ? 'isMobile' : this.getters.isTablet ? 'isTablet' : 'isDesktop']

			const currentPosition = window.scrollY
			const targetPosition = sectionElement.getBoundingClientRect().top + currentPosition - height

			window.scrollTo({
				top: targetPosition,
				behavior: 'smooth',
			})

			state.currentSection = sectionName
			setTimeout(() => (state.isNavigating = false), 600)
		},

		setCurrentSection(state, sectionName) {
			state.currentSection = sectionName
		},

		togglePopUpGallery(state) {
			state.popUpGalleryisOpen = !state.popUpGalleryisOpen
		},
		setSizeIcon(state, { sizeType, mobileSize, tabletSize, desktopSize }) {
			switch (state.device) {
				case 'mobile':
					state[sizeType] = mobileSize
					break
				case 'tablet':
					state[sizeType] = tabletSize
					break
				case 'desktop':
					state[sizeType] = desktopSize
					break
				default:
					state[sizeType] = 0
			}
		},
	},
	actions: {
		getImageFromGalleryPath(context, { name, quality, index }) {
			const baseFolder = require.context('/public/photo', true, /\.(png|jpe?g|svg|jpg)$/)
			const folderPath = `./${name}/${quality}/`
			const images = baseFolder.keys().filter(path => path.startsWith(folderPath))
			const imagePath = baseFolder(images[index])
			return imagePath
		},
		getGalleryLength(context, { name }) {
			const baseFolder = require.context('/public/photo', true, /\.(png|jpe?g|svg|jpg)$/)
			const folderPath = `./${name}/min/`
			const images = baseFolder.keys().filter(path => path.startsWith(folderPath))
			return images.length
		},
	},
	getters: {
		isMobile: state => state.device === 'mobile',
		isTablet: state => state.device === 'tablet',
		isDesktop: state => state.device === 'desktop',
		getCurrentSection: state => state.currentSection,
		isNavigating: state => state.isNavigating,
		isPopUpOpenGallery: state => state.popUpGalleryisOpen,

		getImageFileNameByDevice: state => name => {
			const paths = {
				mobile: `${name}Mobile.png`,
				tablet: `${name}Tablet.png`,
				desktop: `${name}Desktop.png`,
			}

			return state.device === 'mobile' ? paths.mobile : state.device === 'tablet' ? paths.tablet : paths.desktop
		},

		getSizeIconGallery: state => state.sizeIconGallery,
		getSizeIconContact: state => state.sizeIconContact,
	},
})

export default store
