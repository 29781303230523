<template>
	<svg :width="setSize" :height="setSize" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10.2345 45.8543L39.1926 62.5668C39.4154 62.6943 39.6798 62.6882 39.8893 62.5675C50.2237 56.5694 64.5565 48.2567 70.1502 44.9865C70.3636 44.8617 70.4952 44.6333 70.4959 44.3865C70.5014 42.856 70.5091 40.5808 70.5095 40.7242C70.509 40.5623 70.4179 40.2699 70.1605 40.1208L45.4722 25.868L47.6272 24.6164C47.842 24.4924 47.9742 24.2633 47.9749 24.0151C47.9892 19.7221 44.9636 14.4767 41.2317 12.3231C39.2703 11.1887 37.3975 11.0305 35.9659 11.8765C3.39003 30.8204 5.83686 29.3526 5.62692 29.5234C4.28157 30.3561 3.50497 32.0101 3.49065 34.1616C3.47633 38.4546 6.50126 43.7 10.2345 45.8543ZM40.2445 60.7472L40.252 58.7205L69.1085 41.9417L69.101 43.9829L40.2445 60.7472ZM44.0808 26.6767L68.4186 40.7276C56.9554 47.3879 48.8647 52.0886 39.5546 57.4979C34.9178 54.8217 24.3523 48.7237 10.947 40.9866C9.3878 40.0861 8.07066 37.8049 8.07066 36.0065C8.07066 34.5585 8.92901 33.9973 10.2748 34.767C11.8394 35.6785 13.1511 37.9508 13.1388 39.7295C13.1367 39.9804 13.2704 40.2135 13.4885 40.339L16.6859 42.1824C16.9306 42.3249 17.2415 42.2641 17.3834 42.1824C17.5345 42.0954 43.9298 26.765 44.0808 26.6767ZM36.6708 13.0812C37.6546 12.5004 39.0256 12.6612 40.5329 13.5318C43.7658 15.3971 46.4062 19.8476 46.5705 23.6156L17.6615 40.4058C17.6575 40.3717 17.648 40.3366 17.6436 40.3024C17.2178 36.9487 15.1551 33.2921 12.6882 31.1367C11.3852 30.0083 10.0945 29.3654 9.09124 29.1051L36.6708 13.0812ZM11.7719 32.19C14.0848 34.2108 15.8399 37.4653 16.2417 40.3147L14.5214 39.3232C14.3625 37.1259 12.8675 34.6614 10.9722 33.5576C8.65776 32.2334 6.67443 33.489 6.67443 36.0065C6.67443 38.319 8.2445 41.0378 10.2489 42.1961L38.8556 58.7204L38.8481 60.7559L10.9326 44.6449C7.64252 42.7462 4.87392 37.9474 4.88688 34.1684C4.91566 29.7246 8.37252 29.2457 11.7719 32.19Z"
			fill="white"
		/>
		<path
			d="M38.9117 50.5776C39.1317 50.7093 39.4079 50.7093 39.6276 50.5783L46.4942 46.4851C46.7035 46.3603 46.8323 46.136 46.835 45.8926C46.8378 45.6492 46.713 45.4222 46.5071 45.2934L43.4181 43.3626L45.2766 42.2568C45.4899 42.1294 45.6202 41.8982 45.6174 41.6494C45.6147 41.4006 45.4797 41.1722 45.2636 41.0495L39.0229 37.5105L43.714 34.8537L53.7214 40.5102L49.3153 43.3006C49.1135 43.4288 48.9907 43.651 48.9907 43.8903C48.9907 44.1296 49.1135 44.3525 49.3159 44.48L51.743 46.0153C52.0654 46.2205 52.4997 46.1237 52.7056 45.7985C52.9122 45.4727 52.8147 45.0418 52.4888 44.8359L50.9944 43.8903L55.4537 41.0658C55.661 40.9342 55.7844 40.7038 55.7783 40.4584C55.7721 40.213 55.6371 39.9893 55.4237 39.8687L44.0576 33.4445C43.8449 33.3232 43.5838 33.3232 43.3697 33.4445L37.6191 36.7006L35.0503 35.1196C34.8342 34.988 34.569 34.9812 34.3467 35.1025L24.3284 40.6281C24.1095 40.7488 23.9725 40.9772 23.9677 41.2274C23.9637 41.4769 24.0932 41.7101 24.3073 41.8389L38.9117 50.5776ZM34.6651 36.522C34.8047 36.6031 44.1218 42.0151 43.5299 41.6712L41.7205 42.7484C41.5112 42.8731 41.3824 43.0974 41.3797 43.3408C41.3769 43.5842 41.5017 43.8112 41.7076 43.9401L44.7973 45.8708L39.271 49.165L29.6323 43.3977L32.5469 41.4831C32.8694 41.271 32.9587 40.8381 32.7473 40.5163C32.536 40.1939 32.1031 40.1039 31.7806 40.3159L28.3016 42.6014L26.0669 41.2642L34.6651 36.522Z"
			fill="white"
		/>
	</svg>
</template>

<script>
export default {
	name: 'BrickSvg',
	computed: {
		setSize() {
			return this.$store.getters.getSizeIconGallery
		},
	},
}
</script>
