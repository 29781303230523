<template>
	<section>
		<div class="sectionAbout">
			<div class="sectionAbout__bgDecoration">
				<article class="sectionAbout__article">
					<div class="article__header">
						<h2 id="HeaderSectionAbout" class="header__h2">CO TWORZĘ</h2>
						<p class="header__text">
							Jestem doświadczonym stolarzem z ponad 30-letnią praktyką w rzemiośle. Moja stolarnia to miejsce, gdzie
							pasja do drewna spotyka się z precyzją i dbałością o każdy detal. Od 20 lat specjalizuję się głównie w
							tworzeniu schodów, a przez lata realizowałem także wiele innych niebanalnych projektów stolarskich takich
              jak meble na wymiar, nietypowe aranżacje drzwi, jak również nietypowa stolarka wewnętrzna i budowlana.
						</p>
						<p class="header__text">Obecnie moja oferta obejmuje w szczególności:</p>
						<ul class="sectionAbout__ul">
							<li class="ul__li"><p>Schody na beton</p></li>
							<li class="ul__li"><p>Schody samonośne</p></li>
							<li class="ul__li"><p>Docinanie drewna, drzwi i elementów drewnopodobnych na wymiar</p></li>
							<li class="ul__li"><p>Klejenie drewna</p></li>
							<li class="ul__li"><p>Czasem również renowacja drewna</p></li>
						</ul>
						<p class="header__text">
							Zaufaj mojemu doświadczeniu i pasji do drewna.<br />
							Każdy projekt jest dla mnie wyjątkowy, a Twoje zadowolenie jest moim priorytetem.<br /><br />
							Zapraszam do kontaktu i współpracy!
						</p>
					</div>
					<div class="article__images">
						<img :src="getImagePath('photoUp')" class="images__image--photoUp" alt="Photo of stairs" />
						<img :src="getImagePath('photoDown')" class="images__image--photoDown" alt="Photo of stairs" />
					</div>
				</article>
			</div>
			<DecorationBar></DecorationBar>
		</div>
	</section>
</template>
<script>
import DecorationBar from '@/assets/additions/DecorationBar.vue'

export default {
	name: 'SectionAbout',
	components: {
		DecorationBar,
	},
	data() {
		return {
			doIcreateArr: ['Schody na beton.', 'Schody samonośne.'],
		}
	},
	computed: {},
	methods: {
		getImagePath(name) {
			const fileName = this.$store.getters.getImageFileNameByDevice(name)
			return require(`@/assets/sections/sectionAbout/${fileName}`)
		},
	},
}
</script>

<style lang="scss" scoped>
.sectionAbout {
	@include basic-section;
	padding-top: 40px;
	&__bgDecoration {
		@include basic-bgDecoration('@/assets/sections/sectionAbout/bgDecoration.png');
		height: 100%;
	}

	&__article {
		@include basic-article;
		.article__header {
			.header__h2 {
				@include h2;
			}
			.header__text {
				padding-right: 30px;
				margin-bottom: 20px;
				@include text-under-header;
			}

			.sectionAbout__ul {
				margin: 40px 0;
				.ul__li {
					margin-bottom: 30px;
					margin: 0 0 30px 50px;
					font-size: 12px;
					font-weight: 500;
					letter-spacing: -0.42px;
					position: relative;
					cursor: default;

					&::before {
						@include positionBar(50%, 0, 8px, 8px);
						left: -30px;
						border-radius: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}
		.article__images {
			text-align: center;
			font-size: 0px;
			position: relative;

			img {
				filter: brightness(0.8);
				width: 236px;
			}

			.images__image--photoUp {
				margin-bottom: 10px;
			}

			&::after {
				@include positionBar(0px, 30px, 3.5px, 100%);
			}
		}
	}
}

@media (max-width: 1023px) {
	.sectionAbout {
		&__article {
			.article__header {
				.header__text {
					padding-right: 0;
				}
				.sectionAbout__ul {
					position: relative;

					&::before {
						@include positionBar(10px, 0px, 1px, 90%);
						left: 24.4px;
					}
				}
			}
		}
	}
	.sectionAbout__article {
		transform: translateY(-20px);
	}
}

@media (min-width: 1024px) {
	.sectionAbout {
		padding-top: 90px;

		&__article {
			width: 920px;

			.article__header {
				align-self: flex-start;
				transform: translateY(-4.7%);

				.sectionAbout__ul {
					position: relative;

					&::before {
						@include positionBar(10px, 0px, 1px, 95%);
						left: 24.4px;
					}
					.ul__li {
						margin-bottom: 60px;
						font-size: 18px;

						&::before {
							@include positionBar(50%, 0, 10px, 10px);
						}
					}
				}
				.content__text {
					width: 380px;
				}
			}

			.article__images {
				text-align: right;

				img {
					width: 470px;
				}
			}
			.article__images::after {
				right: -15px;
			}
		}
	}
}

@media (min-width: 1500px) {
	.sectionAbout {
		padding-top: 110px;
		&__bgDecoration {
			height: 800px;
		}

		&__article {
			width: 1300px;

			.article__header {
				transform: translateY(-4.5%);

				.sectionAbout__ul {
					margin-top: 40px;

					&::before {
						@include positionBar(10px, 0px, 1px, 95%);
						left: 27px;
					}

					.ul__li::before {
						@include positionBar(50%, 0, 15px, 15px);
					}
				}
			}

			.article__images {
				img {
					width: 680px;
				}
				.images__image--photoUp {
					margin-bottom: 30px;
				}
			}
		}
	}
}
</style>
