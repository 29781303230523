<template>
	<section>
		<div class="contact">
			<div class="contact__icon">
				<component :is="getIconComponent(parentObject.icon)" class="iconSvg"></component>
			</div>
			<p class="contact__info">{{ parentObject.info }}</p>
		</div>
	</section>
</template>

<script>
import HumanIcon from '@/assets/sections/sectionContact/HumanSvg.vue'
import PhoneIcon from '@/assets/sections/sectionContact/PhoneSvg.vue'
import PinIcon from '@/assets/sections/sectionContact/PinSvg.vue'

export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Contact',
	components: {
		HumanIcon,
		PhoneIcon,
		PinIcon,
	},
	props: {
		parentObject: Object,
	},
	computed: {},
	methods: {
		getIconComponent(iconName) {
			const iconMap = {
				HumanSvg: HumanIcon,
				PhoneSvg: PhoneIcon,
				PinSvg: PinIcon,
			}

			return iconMap[iconName]
		},
	},
}
</script>
<style lang="scss" scoped>
.contact {
	@include flex(row, center, none);

	.contact__icon {
		width: 50px;
		height: 50px;
		background-color: $primary-color-dark;
		border-radius: 50%;
		position: relative;

		.iconSvg {
			@include center-absolute;
		}
	}
	.contact__info {
		margin-left: 30px;
		font-size: 16px;
		font-weight: 500;
	}
}

@media (max-width: 1023px) {
	.contact {
		margin-top: 40px;
		width: 320px;
	}
}
@media (min-width: 1024px) {
	.contact {
		.contact__icon {
			width: 40px;
			height: 40px;
		}
		.contact__info {
			margin-left: 20px;
		}
	}
}

@media (min-width: 1500px) {
	.contact {
		.contact__info {
			font-size: 18px;
		}
	}
}
</style>
