<template>
	<svg :width="setSize()" :height="setSize()" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_315_200)">
			<path
				d="M26.0145 11.1139L20.8552 5.95462L19.2645 7.54537L24.0592 12.3412L0 12.375V14.625L24.1267 14.5912L19.2634 19.4546L20.8541 21.0454L26.0145 15.8861C26.6453 15.2522 26.9994 14.3943 26.9994 13.5C26.9994 12.6057 26.6453 11.7478 26.0145 11.1139Z"
				fill="#5B3E31"
			/>
		</g>
		<defs>
			<clipPath id="clip0_315_200">
				<rect width="27" height="27" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'ArrowRight',
	props: {
		sectionName: String,
	},
	computed: {
		isMobile() {
			return this.$store.getters.isMobile
		},
		isTablet() {
			return this.$store.getters.isTablet
		},
	},
	methods: {
		setSize() {
			const mobileSizes = { home: 24, gallery: 19 }
			const tabletSizes = { home: 27, gallery: 27 }
			const desktopSizes = { home: 27, gallery: 40 }

			return this.isMobile
				? mobileSizes[this.sectionName]
				: this.isTablet
				? tabletSizes[this.sectionName]
				: desktopSizes[this.sectionName]
		},
	},
}
</script>
