import { computed } from 'vue';
<template>
	<section>
		<div class="sectionGallery">
			<div class="sectionGallery__bgDecoration">
				<article class="sectionGallery__article">
					<div class="article__header">
						<h2 id="HeaderSectionGallery" class="header__h2">GALERIA</h2>
						<p class="header__text">
							Odkryj unikalne projekty schodów, gdzie tradycja stolarskiego rzemiosła spotyka się z nowoczesnym
							designem.
						</p>
					</div>
					<div class="article__galleries">
						<Gallery :parentObject="galleryConcrete" @gallerySelectedToPopUp="gallerySelectedToPopUp"></Gallery>
						<Gallery
							:parentObject="galleryDown"
							@gallerySelectedToPopUp="gallerySelectedToPopUp"
							class="galleries_galleryDown"
						></Gallery>
					</div>
				</article>
			</div>
			<DecorationBar></DecorationBar>
			<GalleryPopUp v-if="isPopUpOpen" :parentObject="galleryInPopUp"></GalleryPopUp>
		</div>
	</section>
</template>
<script>
import Gallery from './Gallery.vue'
import GalleryPopUp from './GalleryPopUp.vue'
import DecorationBar from '@/assets/additions/DecorationBar.vue'
export default {
	name: 'SectionGallery',
	components: {
		DecorationBar,
		Gallery,
		GalleryPopUp,
	},
	data() {
		return {
			galleryInPopUp: {},
			galleryConcrete: {
				header: 'SCHODY NA BETON',
				icon: 'BrickSvg',
				class: 'concrete',
				images: {
					imagesLength: 3,
					folder: 'beton',
				},
			},
			galleryDown: {
				header: 'SCHODY SAMONOŚNE',
				icon: 'FloorPlanSvg',
				class: 'photoDown',
				images: {
					imagesLength: 3,
					folder: 'samonosne',
				},
			},
			openedGalleryName: String,
		}
	},
	computed: {
		isPopUpOpen() {
			return this.$store.getters.isPopUpOpenGallery
		},
	},
	methods: {
		gallerySelectedToPopUp(galleryData) {
			this.galleryInPopUp = galleryData
			this.$store.commit('togglePopUpGallery')
		},
	},
}
</script>

<style lang="scss" scoped>
.sectionGallery {
	@include basic-section;
	padding-top: 40px;
	&__bgDecoration {
		@include basic-bgDecoration('@/assets/sections/sectionGallery/bgDecoration.png');
		height: 100%;
	}

	&__article {
		@include basic-article;

		.article__header {
			.header__h2 {
				@include h2;
			}

			.header__text {
				@include text-under-header;
			}
		}
		.article__galleries {
			margin-top: 40px;
			margin-right: auto;
		}
	}
}

@media (max-width: 1023px) {
	.sectionGallery__article {
		transform: translateY(-20px);

		.galleries_galleryDown {
			margin-top: 30px;
		}
	}
}

@media (min-width: 1024px) {
	.sectionGallery {
		padding-top: 90px;

		&__article {
			width: 920px;
			flex-direction: column;
			transform: translateY(-4%);

			.article__header {
				align-self: flex-start;

				.header__text {
					width: 420px;
				}
			}

			.article__galleries {
				display: flex;
				padding-bottom: 40px;
			}
		}
	}
}
@media (min-width: 1500px) {
	.sectionGallery {
		padding-top: 110px;

		&__article {
			width: 1300px;
			transform: translateY(-5%);

			.article__galleries {
				margin-top: 50px;
			}
		}
	}
}
</style>
