<template>
	<svg :width="setSize" :height="setSize" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.2122 17.9326C20.1252 15.9623 21.1998 13.2905 21.1998 10.5048C21.1998 7.71902 20.1252 5.04728 18.2122 3.07694C17.2653 2.10147 16.1409 1.32765 14.9034 0.799696C13.6659 0.271741 12.3394 0 10.9998 0C9.66021 0 8.33374 0.271741 7.0962 0.799696C5.85867 1.32765 4.73431 2.10147 3.7874 3.07694C1.87442 5.04728 0.799805 7.71902 0.799805 10.5048C0.799805 13.2905 1.87442 15.9623 3.7874 17.9326L6.00357 20.1811L8.98033 23.1585L9.17412 23.3355C10.3033 24.2775 11.9571 24.2175 13.0207 23.1585L16.5687 19.6036L18.2122 17.9326ZM10.9998 15.0002C9.8405 15.0002 8.72868 14.5261 7.90893 13.6822C7.08918 12.8383 6.62865 11.6937 6.62865 10.5003C6.62865 9.30683 7.08918 8.16226 7.90893 7.31837C8.72868 6.47447 9.8405 6.00037 10.9998 6.00037C12.1591 6.00037 13.2709 6.47447 14.0907 7.31837C14.9104 8.16226 15.371 9.30683 15.371 10.5003C15.371 11.6937 14.9104 12.8383 14.0907 13.6822C13.2709 14.5261 12.1591 15.0002 10.9998 15.0002Z"
			fill="white"
		/>
	</svg>
</template>

<script>
export default {
	name: 'PinSvg',
	computed: {
		setSize() {
			return this.$store.getters.getSizeIconContact
		},
	},
}
</script>
