<template>
	<section>
		<div class="sectionHome">
			<div class="sectionHome__bgDecoration">
				<article class="sectionHome__article">
					<div class="article__images">
						<div class="images__image images__image--photoUp">
							<img :src="getImagePath('photoUp')" alt="Photo of a carpenter at work" />
						</div>
						<div class="images__image images__image--photoDown">
							<img :src="getImagePath('photoDown')" alt="Photo of a carpenter at work" />
						</div>
					</div>

					<div class="article__content">
						<h1 id="HeaderSectionHome" class="content__h1">Witam w mojej stolarni!</h1>
						<p class="content__text">
              Z pasją i precyzją tworzę unikalne schody oraz realizuję drobne prace stolarskie,
              które spełniają najwyższe standardy jakości. Zaufaj mojemu ponad 30-letniemu doświadczeniu i zobacz,
              jak mogę przekształcić Twoje wnętrze.<br /><br />
              <strong>Odkryj moją ofertę i przekonaj się, dlaczego warto mi zaufać!</strong>
						</p>
						<div class="content__btns">
							<div class="btns__btn btns__btn--contact" @click="navigateToSection('SectionContact')">Kontakt</div>
							<div class="btns__btn btns__btn--showMore" @click="navigateToSection('SectionGallery')">
								Zobacz więcej<ArrowRight :sectionName="'home'"></ArrowRight>
							</div>
						</div>
					</div>
				</article>
			</div>
			<DecorationBar></DecorationBar>
		</div>
	</section>
</template>
<script>
import ArrowRight from '@/assets/additions/ArrowRightSvg.vue'
import DecorationBar from '@/assets/additions/DecorationBar.vue'

export default {
	name: 'SectionHome',
	components: {
		ArrowRight,
		DecorationBar,
	},
	computed: {
		isMobile() {
			return this.$store.getters.isMobile
		},
		isDesktop() {
			return this.$store.getters.isDesktop
		},
	},
	methods: {
		getImagePath(name) {
			const fileName = this.$store.getters.getImageFileNameByDevice(name)
			return require(`@/assets/sections/sectionHome/${fileName}`)
		},
		navigateToSection(sectionName) {
			this.$store.commit('navigateToSection', sectionName)
		},
	},
}
</script>

<style lang="scss" scoped>
.sectionHome {
	@include basic-section;
	padding-top: 155px;

	&__bgDecoration {
		@include basic-bgDecoration('@/assets/sections/sectionHome/bgDecoration.png');
		height: 560px;
	}

	&__article {
		@include basic-article;

		.article__images {
			@include flex(row, center, center);
			height: 45%;

			.images__image {
				img {
					box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.35);
				}

				&--photoUp {
					transform: translateY(-90px) translateX(20px);

					&::before {
						@include positionBar(0px, -10px, 4px, 79px);
					}

					&::after {
						@include positionBar(75px, -60px, 50px, 4px);
					}
				}

				&--photoDown {
					transform: translateY(-10px) translateX(-30px);

					&::before {
						@include positionBar(68px, 115px, 4px, 67px);
					}

					&::after {
						@include positionBar(67px, 115px, 63px, 4px);
					}
				}
			}
		}

		.article__content {
			height: 55%;
			width: 100%;

			.content__h1 {
				font-size: 44px;
				line-height: 40px;
			}

			.content__text {
				@include text-under-header;
			}

			.content__btns {
				height: 45px;
				width: 100%;
				margin-top: 20px;
				display: flex;
				justify-content: space-between;

				.btns__btn {
					@include cursor;
					@include flex(none, center, center);
					font-size: 14px;
				}
				.btns__btn--contact {
					width: 105px;
					background-color: $primary-color-dark;
					color: $white;
				}

				.btns__btn--showMore {
					@include flex(none, center, space-evenly);
					width: 170px;
					border: 2px solid $primary-color-dark;
				}
			}
		}
	}
}

@media (min-width: 1024px) {
	.sectionHome {
		padding-top: 180px;

		&__bgDecoration {
			height: 525px;
		}

		&__article {
			width: 880px;
			transform: translateX(25px);

			.article__images {
				height: 100%;
				width: 50%;

				.images__image {
					&--photoUp {
						transform: translateY(-130px) translateX(20px);

						&::before {
							@include positionBar(0px, -20px, 5px, 190px);
						}

						&::after {
							@include positionBar(185px, -137px, 120px, 5px);
						}
					}

					&--photoDown {
						transform: translateY(60px) translateX(-70px);

						&::before {
							@include positionBar(115px, 240px, 5px, 163px);
						}

						&::after {
							@include positionBar(115px, 240px, 145px, 5px);
						}
					}
				}
			}

			.article__content {
				width: 50%;

				.content__btns {
					height: 50px;
					width: 80%;
					margin-top: 40px;

					.btns__btn--showMore {
						width: 185px;
						transform: translateX(-30px);
					}
				}
			}
		}
	}

	@media (min-width: 1500px) {
		.sectionHome {
			padding-top: 220px;

			&__bgDecoration {
				height: 600px;
			}

			&__article {
				width: 1300px;
				transform: translateX(-80px);

				.article__images {
					width: 60%;

					.images__image {
						&--photoUp {
							transform: translateY(-130px) translateX(20px);

							&::before {
								@include positionBar(0px, -20px, 5px, 265px);
							}

							&::after {
								@include positionBar(260px, -252px, 232px, 5px);
							}
						}

						&--photoDown {
							transform: translateY(130px) translateX(-70px);

							&::before {
								@include positionBar(195px, 357px, 5px, 225px);
							}

							&::after {
								@include positionBar(195px, 360px, 268px, 5px);
							}
						}
					}
				}

				.article__content {
					transform: translateY(20px) translateX(50px);

					.content__h1 {
						width: 700px;
					}

          .content__text {
            width: 600px;
            text-align: justify;
          }

					.content__btns {
						.btns__btn--contact {
							width: 115px;
						}

						.btns__btn--showMore {
							transform: translateX(-100px);
						}
					}
				}
			}
		}
	}
}
</style>
