<template>
	<div class="decoration-bar"></div>
</template>

<script>
export default {
	name: 'DecorationBar',
	computed: {
		isMobile() {
			return this.$store.getters.isMobile
		},
	},
	methods: {
		setSize() {
			return this.isMobile ? 24 : 27
		},
	},
}
</script>

<style lang="scss" scoped>
.decoration-bar {
	width: 100%;
	height: 15px;
	margin-top: 50px;
	background-color: $primary-color-dark;

	@media (min-width: 1024px) {
		height: 20px;
		margin-top: 60px;
	}

	@media (min-width: 1500px) {
		margin-top: 100px;
	}
}
</style>
