<template>
	<svg :width="setSize" :height="setSize" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.62367 19.2067C2.15046 17.9269 2.91491 16.7643 3.87446 15.7839C4.83109 14.8006 5.96431 14.0166 7.21153 13.4752C7.2227 13.4695 7.23387 13.4666 7.24504 13.4609C5.5053 12.172 4.37432 10.0724 4.37432 7.70362C4.37432 3.7795 7.47403 0.600098 11.2998 0.600098C15.1256 0.600098 18.2253 3.7795 18.2253 7.70362C18.2253 10.0724 17.0943 12.172 15.3546 13.4638C15.3657 13.4695 15.3769 13.4724 15.3881 13.4781C16.6391 14.0194 17.7617 14.7957 18.7252 15.7867C19.6838 16.768 20.4481 17.9303 20.9759 19.2096C21.4944 20.462 21.7741 21.8048 21.7997 23.1652C21.8005 23.1958 21.7953 23.2262 21.7844 23.2547C21.7735 23.2831 21.7571 23.3091 21.7363 23.331C21.7155 23.3529 21.6906 23.3703 21.6631 23.3821C21.6357 23.394 21.6061 23.4001 21.5763 23.4001H19.9008C19.7779 23.4001 19.6802 23.2998 19.6774 23.1767C19.6216 20.9654 18.7559 18.8945 17.2256 17.3249C15.6422 15.7008 13.5394 14.8071 11.2998 14.8071C9.06019 14.8071 6.95741 15.7008 5.37405 17.3249C3.84374 18.8945 2.97805 20.9654 2.9222 23.1767C2.91941 23.3027 2.82167 23.4001 2.6988 23.4001H1.02328C0.993466 23.4001 0.963951 23.394 0.936476 23.3821C0.909001 23.3703 0.88412 23.3529 0.8633 23.331C0.842481 23.3091 0.826145 23.2831 0.815252 23.2547C0.804359 23.2262 0.79913 23.1958 0.799875 23.1652C0.827801 21.7961 1.10426 20.4642 1.62367 19.2067ZM11.2998 12.6302C12.5816 12.6302 13.788 12.1175 14.6955 11.1866C15.6031 10.2557 16.103 9.01834 16.103 7.70362C16.103 6.38889 15.6031 5.1515 14.6955 4.2206C13.788 3.2897 12.5816 2.77698 11.2998 2.77698C10.018 2.77698 8.81166 3.2897 7.90408 4.2206C6.99651 5.1515 6.49665 6.38889 6.49665 7.70362C6.49665 9.01834 6.99651 10.2557 7.90408 11.1866C8.81166 12.1175 10.018 12.6302 11.2998 12.6302Z"
			fill="white"
		/>
	</svg>
</template>

<script>
export default {
	name: 'HumanSvg',
	computed: {
		setSize() {
			return this.$store.getters.getSizeIconContact
		},
	},
}
</script>
