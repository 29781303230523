<template>
	<NavBar></NavBar>
	<main>
		<SectionHome ref="SectionHome"></SectionHome>
		<SectionAbout ref="SectionAbout"></SectionAbout>
		<SectionGallery ref="SectionGallery"></SectionGallery>
		<SectionContact ref="SectionContact"></SectionContact>
	</main>
</template>

<script>
import NavBar from './components/navBar/NavBar.vue'
import SectionHome from './components/sections/sectionHome/SectionHome.vue'
import SectionAbout from './components/sections/sectionAbout/SectionAbout.vue'
import SectionGallery from './components/sections/sectionGallery/SectionGallery.vue'
import SectionContact from './components/sections/sectionContact/SectionContact.vue'

export default {
	name: 'App',
	components: {
		NavBar,
		SectionHome,
		SectionAbout,
		SectionGallery,
		SectionContact,
	},
	created() {
		window.addEventListener('resize', this.handleResize)
		window.addEventListener('scroll', this.handleScroll)
		this.setDevice(window.innerWidth)
	},
  mounted() {
    const meta = document.createElement('meta')
    meta.name = 'google-site-verification'
    meta.content = 'X6AGEgagaeBdvvjuyVHAMTVxQI6aiswHCF7rY0bPDNg'
    document.getElementsByTagName('head')[0].appendChild(meta)
  },
	unmounted() {
		window.removeEventListener('resize', this.handleResize)
		window.removeEventListener('scroll', this.handleScroll)
	},
	computed: {
		isNavigating() {
			return this.$store.getters.isNavigating
		},
		navbarLinksArray() {
			return this.$store.state.navbarLinks.map(section => section.name)
		},
	},
	methods: {
		handleResize() {
			const width = window.innerWidth
			this.setDevice(width)
			this.isSticky = window.scrollY > 0
		},
		setDevice(width) {
			if (width < 1024) {
				this.$store.commit('setDevice', 'mobile')
			} else if (width > 1023 && width < 1500) {
				this.$store.commit('setDevice', 'tablet')
			} else {
				this.$store.commit('setDevice', 'desktop')
			}
		},
		handleScroll() {
			if (!this.isNavigating) {
				const scrollPosition = window.scrollY
				const modifiedNavbarLinksArray = [...this.navbarLinksArray]

				for (const sectionRef of modifiedNavbarLinksArray) {
					const section = this.$refs[sectionRef]
					const { offsetTop, offsetHeight } = section.$el
					let sectionName = sectionRef
					if (scrollPosition >= offsetTop - offsetHeight * 0.2 && scrollPosition < offsetTop + offsetHeight * 0.8) {
						this.$store.commit('setCurrentSection', sectionName)
					}
				}
			}
		},
	},
}
</script>
<style lang="scss">
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
</style>
