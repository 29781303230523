<template>
	<svg :width="setSize" :height="setSize" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M24.4184 0.581592C24.0459 0.209199 23.5407 0 23.014 0C22.4873 0 21.9821 0.209199 21.6096 0.581592L12.5 9.69123L3.39036 0.581592C3.01786 0.209199 2.5127 0 1.98598 0C1.45925 0 0.954097 0.209199 0.581592 0.581592C0.209199 0.954097 0 1.45925 0 1.98598C0 2.5127 0.209199 3.01786 0.581592 3.39036L9.69123 12.5L0.581592 21.6096C0.209199 21.9821 0 22.4873 0 23.014C0 23.5407 0.209199 24.0459 0.581592 24.4184C0.954097 24.7908 1.45925 25 1.98598 25C2.5127 25 3.01786 24.7908 3.39036 24.4184L12.5 15.3088L21.6096 24.4184C21.9821 24.7908 22.4873 25 23.014 25C23.5407 25 24.0459 24.7908 24.4184 24.4184C24.7908 24.0459 25 23.5407 25 23.014C25 22.4873 24.7908 21.9821 24.4184 21.6096L15.3088 12.5L24.4184 3.39036C24.7908 3.01786 25 2.5127 25 1.98598C25 1.45925 24.7908 0.954097 24.4184 0.581592Z"
			fill="black"
		/>
	</svg>
</template>

<script>
export default {
	name: 'BrickSvg',
	computed: {
		setSize() {
			return this.$store.getters.getSizeIconContact
		},
	},
}
</script>
