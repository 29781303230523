<template>
	<footer>
		<div class="sectionContact">
			<div class="sectionContact__bgDecoration">
				<article class="sectionContact__article">
					<h2 id="HeaderSectionContact" class="article__header">KONTAKT</h2>
					<div class="article__contacts">
						<Contact :parentObject="name"></Contact>
						<Contact :parentObject="number"></Contact>
						<Contact :parentObject="adres"></Contact>
					</div>
					<div class="contact__map">
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10209.669307675678!2d19.215618!3d50.228108!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x47fb7a2be546bef6!2sUs%C5%82ugi+stolarskie+%C5%9Alusarczyk+Maciej+-+Schody+drewniane!5e0!3m2!1spl!2spl!4v1491817893245"
							width="100%"
							height="450"
							frameborder="0"
							style="border: 0; border-radius: 10px"
							allowfullscreen
						></iframe>
					</div>
				</article>
			</div>
			<p class="sectionContact__copyright">Copyright © by Pracownia Stolarska Maciej Ślusarczyk. All right reserved.</p>
			<DecorationBar class="sectionContact__decorationBar"></DecorationBar>
		</div>
	</footer>
</template>
<script>
import Contact from './Contact.vue'
import DecorationBar from '@/assets/additions/DecorationBar.vue'

export default {
	name: 'SectionContact',
	components: {
		DecorationBar,
		Contact,
	},
	data() {
		return {
			name: {
				info: 'Maciej Ślusarczyk',
				icon: 'HumanSvg',
				class: 'name',
			},
			number: {
				info: '+48 605 874 103 (10:00-20:00)',
				icon: 'PhoneSvg',
				class: 'number',
			},
			adres: {
				info: 'Długoszyńska 3A, Jaworzno 43-600',
				icon: 'PinSvg',
				class: 'adres',
			},
		}
	},
	computed: {
		isMobile() {
			return this.$store.getters.isMobile
		},
	},
}
</script>

<style lang="scss" scoped>
.sectionContact {
	@include basic-section;
	padding-top: 40px;
	position: relative;
	&__bgDecoration {
		@include basic-bgDecoration('@/assets/sections/sectionContact/bgDecoration.png');
		height: 100%;
	}

	&__article {
		@include basic-article;
		transform: translateY(-3%);

		.sectionContact__article {
			width: 320px;
		}
		.article__header {
			@include h2;
			margin-right: auto;
		}
	}

	.sectionContact__copyright {
		position: absolute;
		right: 3%;
		bottom: 1%;
		font-size: 12px;
	}

	.sectionContact__decorationBar {
		height: 30px;
	}
}
@media screen {
	.contact__map {
		margin-top: 20px;
	}
}
@media (min-width: 1024px) {
	.sectionContact {
		padding-top: 90px;

		&__article {
			width: 920px;
			flex-direction: column;
			transform: translateY(-5%);

			.article__contacts {
				@include flex(row, center, space-around);
				width: 100%;
				margin-top: 60px;
			}

			.contact__map {
				height: 450px;
				width: 910px;
				margin-top: 20px;
			}
		}

		.sectionContact__copyright {
			bottom: 2%;
			font-size: 14px;
		}
		.sectionContact__decorationBar {
			height: 50px;
		}
	}
}
@media (min-width: 1500px) {
	.sectionContact {
		padding-top: 110px;

		&__article {
			width: 1300px;
			transform: translateY(-5%);

			.contact__map {
				height: 440px;
				width: 1300px;
				margin-top: 20px;
			}
		}
	}
}
</style>
