<template>
	<svg :width="setSize" :height="setSize" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17.4558 9.8854C15.7278 13.4784 12.9438 16.4239 9.5478 18.2521L6.9078 15.459C6.5718 15.1035 6.1038 15.0019 5.6838 15.1416C4.3398 15.6113 2.8998 15.8653 1.3998 15.8653C1.08154 15.8653 0.776318 15.999 0.551275 16.2371C0.326231 16.4752 0.199804 16.7982 0.199804 17.1349V21.5785C0.199804 21.9152 0.326231 22.2382 0.551275 22.4763C0.776318 22.7144 1.08154 22.8481 1.3998 22.8481C6.81022 22.8481 11.999 20.5742 15.8248 16.5265C19.6505 12.4788 21.7998 6.98901 21.7998 1.26473C21.7998 0.928007 21.6734 0.605076 21.4483 0.366978C21.2233 0.128879 20.9181 -0.00488281 20.5998 -0.00488281H16.3998C16.0815 -0.00488281 15.7763 0.128879 15.5513 0.366978C15.3262 0.605076 15.1998 0.928007 15.1998 1.26473C15.1998 2.85174 14.9598 4.37528 14.5158 5.79725C14.3838 6.24161 14.4798 6.73676 14.8158 7.09225L17.4558 9.8854Z"
			fill="white"
		/>
	</svg>
</template>

<script>
export default {
	name: 'PhoneSvg',
	computed: {
		setSize() {
			return this.$store.getters.getSizeIconContact
		},
	},
}
</script>
